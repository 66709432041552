<template>
  <div class="container-box">
    <div class="main-layout-wrap">
      <div class="side-menu-left">
        <!-- 头像及用户名称 -->
        <div class="top-Div">
          <img
            class="bg-img"
            src="@/assets/image/personalCenter/2022050901.png"
            alt=""
          />
          <div class="head-img">
            <img
              :src="userInfo.photo ? userInfo.photo : GLOBAL.defaultUserImg"
              alt=""
            />
            <div class="modify" @click="onclickImg">
              <span>修改</span>
              <input
                type="file"
                ref="file"
                @change="preview"
                accept="image/jpg, image/png, image/jpeg"
                style="display: none"
              />
            </div>
          </div>
          <!-- 身份认证标识 -->
          <div class="identity-icon">
            <!-- 个人普通用户 -->
            <img
              v-if="userType == 0"
              class="label_item"
              src="@/assets/image/personalCenter/2022050903.png"
              mode="widthFix"
            />
            <!-- 家长 -->
            <img
              v-if="
                userType == 1 || userType == 4 || userType == 5 || userType == 7
              "
              class="label_item"
              src="@/assets/image/personalCenter/2022050904.png"
              mode="widthFix"
            />
            <!-- 机构 -->
            <img
              v-if="
                userType == 3 || userType == 5 || userType == 6 || userType == 7
              "
              class="label_item"
              src="@/assets/image/personalCenter/2022050902.png"
              mode="widthFix"
            />
            <!-- 从业者 -->
            <img
              v-if="
                userType == 2 || userType == 4 || userType == 6 || userType == 7
              "
              class="label_item"
              src="@/assets/image/personalCenter/2022050905.png"
              mode="widthFix"
            />
          </div>
          <p class="net-name">
            <span>{{
              userInfo.userName ? userInfo.userName : userInfo.mobile
            }}</span>
            <img
              @click="
                (isEditNameShow = true),
                  (userName = userInfo.userName
                    ? userInfo.userName
                    : userInfo.mobile)
              "
              src="@/assets/image/personalCenter/2022050907.png"
              alt=""
            />
          </p>
        </div>
        <!-- 导航菜单 -->
        <div class="bottom-menu-Div">
          <a-menu
            :open-keys.sync="openKeys"
            mode="inline"
            :selectedKeys="selectedKeys"
          >
            <a-menu-item
              @click="goPath(item.idnum, 'single')"
              v-for="item in menuList"
              :key="item.idnum"
            >
              <div class="menus-item">
                <img
                  :src="menuIndex == item.idnum ? item.iconActive : item.icon"
                  alt=""
                />
                <span>{{ item.name }}</span>
                
              </div>
            </a-menu-item>
            <a-sub-menu key="sub2">
              <div slot="title" class="menus-item">
                <img
                  src="@/assets/image/personalCenter/20220727-152355.png"
                  alt=""
                /><span>设置</span>
              </div>
              <a-menu-item
                @click="goPath(item.idnum, 'many')"
                v-for="item in menuList2"
                :key="item.idnum"
              >
                <span
                  class="title"
                  :class="{ selhover: selectedKeys[0] == item.idnum }"
                  >{{ item.name }}</span
                >
              </a-menu-item>
            </a-sub-menu>
          </a-menu>
          <p class="log-out" @click="isSignoutShow = true">
            <img src="@/assets/image/personalCenter/2022050908.png" alt="" />
            <span>退出登录</span>
          </p>
        </div>
      </div>
      <div class="side-view-right">
        <router-view />
      </div>
      <!-- 修改用户名弹窗 -->
      <a-modal
        v-model="isEditNameShow"
        width="480px"
        :closable="false"
        @ok="onLoginOut"
      >
        <div class="modal-content">
          <p class="tips">修改用户名</p>
          <a-input class="input" v-model="userName" />
          <div class="btn-foot">
            <p class="cancel" @click="isEditNameShow = false">取消</p>
            <p class="all-btn-small" @click="onSave">确认</p>
          </div>
        </div>
        <template #footer></template>
      </a-modal>
      <!-- 确认登录弹窗 -->
      <a-modal
        v-model="isSignoutShow"
        width="480px"
        :closable="false"
        @ok="onLoginOut"
      >
        <div class="modal-content">
          <p class="tips">
            <img
              src="@/assets/image/personalCenter/2022050913.png"
              alt=""
            />提示信息
          </p>
          <p class="content">确认退出登录？</p>
          <div class="btn-foot">
            <p class="cancel" @click="isSignoutShow = false">取消</p>
            <p class="all-btn-small" @click="onSignOut">确认</p>
          </div>
        </div>
        <template #footer></template>
      </a-modal>

      <!-- 加载 -->
      <div class="load_box" v-if="loading">
        <a-spin class="load" size='large' />
      </div>
    </div>
  </div>
</template>

<script>
// import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
// import { defineComponent, ref, createVNode } from 'vue';
import { uploadObject } from "@/unit/cosUpload.js";
import { Modal } from "ant-design-vue";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 一级选择
      menuList: [
        {
          icon: require("@/assets/image/personalCenter/tab_icon1.png"),
          iconActive: require("@/assets/image/personalCenter/tab_icon1_active.png"),
          name: "身份认证",
          path: "/personalCenter/identityAuthentication",
          idnum: 1,
        },
        {
          icon: require("@/assets/image/personalCenter/tab_icon2.png"),
          iconActive: require("@/assets/image/personalCenter/tab_icon2_active.png"),
          name: "我的订单",
          path: "/personalCenter/MyOrder",
          idnum: 2,
        },
        // {
        //   icon: require("@/assets/image/personalCenter/tab_icon3.png"),
        //   iconActive: require("@/assets/image/personalCenter/tab_icon3_active.png"),
        //   name: "学习统计",
        //   path: "/personalCenter/LearnStatistics",
        //   idnum: 9,
        // },
        {
          icon: require("@/assets/image/personalCenter/tab_icon3.png"),
          iconActive: require("@/assets/image/personalCenter/tab_icon3_active.png"),
          name: "我的优惠券",
          path: "/personalCenter/myCoupon",
          idnum: 3,
        },
        // {
        //   icon: require("@/assets/image/personalCenter/tab_icon4.png"),
        //   iconActive: require("@/assets/image/personalCenter/tab_icon4_active.png"),
        //   name: "我的收藏",
        //   path: "",
        //   idnum: 4,
        // },
      ],
      // 二级选择
      menuList2: [
        {
          icon: require("@/assets/image/personalCenter/icon1.png"),
          iconActive: require("@/assets/image/personalCenter/icon1_active.png"),
          name: "修改手机号",
          idnum: 5,
          path: "/personalCenter/cellphoneNumber",
        },
        {
          icon: require("@/assets/image/personalCenter/icon2.png"),
          iconActive: require("@/assets/image/personalCenter/icon2_active.png"),
          idnum: 6,
          name: "设置密码",
          path: "/personalCenter/Password?set=true",
          // path: "/login/findPassword?set=true",
        },
        {
          icon: require("@/assets/image/personalCenter/icon2.png"),
          iconActive: require("@/assets/image/personalCenter/icon2_active.png"),
          idnum: 7,
          name: "收货地址",
          path: "/personalCenter/addressmanage",
        },
        {
          icon: require("@/assets/image/personalCenter/icon2.png"),
          iconActive: require("@/assets/image/personalCenter/icon2_active.png"),
          name: "发票抬头",
          idnum: 8,
          path: "/personalCenter/inVBRK",
        },
      ],
      current: ["mail"],
      openKeys: ["sub2"], //展开的数组
      selectedKeys: [1], //选中的项
      rootSubmenuKeys: ["sub1", "sub2", "sub4"],
      // 一级选择
      menuIndex: 1,
      // 二级选择
      selindex: null,
      isSignoutShow: false,
      isEditNameShow: false,
      userInfo: {},
      userName: "",
      userType: "",
      isHavePSW: false,
      loading: false, //加载
    };
  },
  // 事件处理器
  methods: {
    // 右侧导航事件
    handleClick(e) {
      this.$nextTick(() => {
        let arry = [];
        arry.push(e.key);
        this.selectedKeys = arry;

        this.menuIndex = e.key;

        let arr = this.menuList;
        for (let index = 0; index < arr.length; index++) {
          if (arr[index].idnum == e.key) {
            this.$router.push(arr[index].path);
          }
        }
      });
    },

    //点击图片唤起本地文件夹
    onclickImg() {
      this.$refs.file.click();
    },
    //上传图片
    preview(e) {
      if (!this.$regular.imgReg.reg.test(e.target.files[0].name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      let _this = this;
      let file = e.target.files[0];
      if (!file) {
        return;
      }

      // 腾讯云对象存储上传文件
      const bucket = 'user/head/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）

      uploadObject(file, bucket, _this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          _this.setPhoto(url);
        } else {
          _this.$message.error("上传失败");
        }
      });

    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 修改头像
    setPhoto(e) {
      this.loading = true;
      this.$ajax({
        url:
          "/hxclass-pc/user/update?isSysUser=0&photo=" +
          e +
          "&userId=" +
          this.$store.state.userInfo.userId,
        method: "PUT",
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.$message.success("修改成功");
          let obj = this.$store.state.userInfo;
          obj.photo = e;
          this.$store.commit("updateUserInfo", obj); // 更新个人信息
          this.userInfo = this.$store.state.userInfo;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 路由跳转
    goPath(i, type) {
      // 判断是单菜单或多菜单
      let arr = type == "single" ? this.menuList : this.menuList2;
      for (let index = 0; index < arr.length; index++) {
        if (arr[index].idnum == i) {
          this.$router.push(arr[index].path);
        }
      }
      // 菜单选中索引赋值
      this.menuIndex = i;

      // 重置选中项
      let selectKey = [];
      selectKey.push(i);
      this.selectedKeys = selectKey;
    },
    // 刷新页面定位页面
    routePath(path) {
      switch (path) {
        case "/personalCenter/identityAuthentication": // 身份认证
          this.selectedKeys[0] = 1;
          this.menuIndex = 1;
          break;
        case "/personalCenter/MyOrder": // 我的订单
          this.selectedKeys[0] = 2;
          this.menuIndex = 2;
          break;
        case "/personalCenter/myCoupon": // 我的优惠券
          this.selectedKeys[0] = 3;
          this.menuIndex = 3
          break;
        case "/personalCenter/cellphoneNumber": // 修改手机号
          this.selectedKeys[0] = 5;
          this.menuIndex = 5;
          break;
        case "/personalCenter/Password": // 修改密码
          this.selectedKeys[0] = 6;
          this.menuIndex = 6;
          break;
        case "/personalCenter/addressmanage": // 地址管理
          this.selectedKeys[0] = 7;
          this.menuIndex = 7;
          break;
        case "/personalCenter/inVBRK": // 发票抬头
          this.selectedKeys[0] = 8;
          this.menuIndex = 8;
          break;
        case "/personalCenter/LearnStatistics": // 学习统计
          this.selectedKeys[0] = 9;
          this.menuIndex = 9;
          break;
      }
    },
    // 退出登录弹窗
    onLoginOut() {
      Modal.confirm({
        title: "提示信息",
        content: "确认退出登录？",
        okText: "确认",
        cancelText: "取消",
      });
    },
    async onSignOut() {
      await this.$store.dispatch('logout');
      this.isSignoutShow = false;
      this.$store.commit("clearUserInfo");
      this.$router.push({
        path: "/login/loginIndex?type=2",
        // query: {type: 2}
      });
    },
    // 用户名修改
    onSave() {
      if (this.userName.length > 20) {
        this.$message.warning("用户名长度不能超过20个字符");
        return;
      }
      this.$ajax({
        url:
          "/hxclass-pc/user/update?isSysUser=0&userName=" +
          this.userName +
          "&userId=" +
          this.userInfo.userId,
        method: "PUT",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success('修改成功');
          this.isEditNameShow = false;
          let obj = this.$store.state.userInfo;
          obj.userName = this.userName;
          this.$store.commit("updateUserInfo", obj); // 更新个人信息
          this.userInfo = this.$store.state.userInfo;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.userInfo = this.$store.state.userInfo;
    this.userType = this.userInfo.userType;

    // 检查是否有密码
    // this.isHavePSW = this.$store.state.userInfo.password ? true : false;
    // if (!this.isHavePSW) {
    //   this.menuList2[1].name = "完善密码";
    // }

    this.routePath(this.$route.path);
  },
  // 生命周期-实例挂载后调用
  mounted() {
    // this.$emit('setIndex', false);
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    $route() {
      this.routePath(this.$route.path);
    },
    openKeys(val) {
      // console.log("openKeys", val);
    },
    '$store.state.userInfo.userType'(newVal, oldVal) {
      this.userType = newVal;
    }
  },
};
</script>

<style lang="less" scoped>
.main-layout-wrap {
  display: flex;
  width: 1400px;
  margin: 20px auto 67px auto;
  .side-menu-left {
    min-width: 300px;
    width: 300px;
    margin-right: 15px;
    overflow: hidden;
    .top-Div {
      border-radius: 14px 14px 0px 0px;
      background: #ffffff;
      text-align: center;
      padding-bottom: 20px;
      .bg-img {
        width: 100%;
        border-bottom: 4px solid #f6f6fc;
      }
      .head-img {
        display: inline-block;
        position: relative;
        bottom: 60px;
        border-radius: 50%;
        overflow: hidden;
        width: 110px;
        height: 110px;
        border: 4px solid #f6f6fc;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          object-fit: cover;
        }
        .modify {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 23px;
          line-height: 23px;
          color: #ffffff;
          font-size: 12px;
          text-align: center;
          background: rgba(0, 0, 0, 0.28);
          cursor: pointer;
        }
      }
      .identity-icon {
        margin-top: -45px;
        .label_item {
          height: 18px;
          margin-right: 12px;
          &:nth-last-child(1) {
            margin-right: 0;
          }
        }
        // .label_item {
        //   border-radius: 50%;
        //   position: relative;
        //   bottom: 7px;
        //   border: 4px solid #f6f6fc;
        // }
        // .left-top-icon {
        //   position: relative;
        //   right: 40px;
        //   bottom: 36px;
        // }
        // .left-bottom-icon {
        //   position: relative;
        //   right: 36px;
        //   bottom: 9px;
        // }
      }
      .net-name {
        margin-top: 22px;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 36px;
        span {
          padding: 0 8px;
        }
        img {
          width: 16px;
          margin-left: 3px;
          cursor: pointer;
        }
      }
    }

    .bottom-menu-Div {
      width: 100%;
      overflow: hidden;
      margin-top: 20px;
      padding: 38px 0;
      border-radius: 0px 0px 14px 14px;
      background: #ffffff;
      .log-out {
        width: 150px;
        height: 40px;
        border: 1px solid rgba(21, 183, 221, 0.5);
        margin: 50px auto 0;
        text-align: center;
        color: @theme;
        line-height: 40px;
        border-radius: 2px 2px 2px 2px;
        cursor: pointer;
        img {
          position: relative;
          top: -2px;
          margin-right: 3px;
        }
      }

      .menus-item {
        display: flex;
        align-items: center;
        // margin-top: 32px;
        width: 100%;
        // height: 70px;
        overflow: hidden;
        color: #787d82;
        font-size: 18px;
        font-weight: 400;
        // border-left: 8px solid #ffffff;

        img {
          width: 16px;
          // height: 18px;
          margin-right: 9px;
          margin-left: 50px;
        }
        span {
          color: #787d82;
        }
      }
      .title {
        margin-left: 64px;
        font-size: 16px;
        font-family: PingFang HK;
        font-weight: 400;
        color: #787d82;
      }

      /deep/.ant-menu-item {
        width: 300px;
        line-height: 60px;
        height: 59px;
      }

      /deep/ .ant-menu-inline {
        border-right: none !important;
      }
    }
  }

  .side-view-right {
    flex: 1;
    background: #ffffff;
    padding: 30px 40px;
    border-radius: 10px;
    /deep/ .title-h4 {
      font-size: 22px;
      font-weight: 500;
      color: @theme;
      line-height: 36px;
      padding-left: 4px;
      margin-bottom: 20px;
      &::before {
        content: "";
        display: inline-block;
        position: relative;
        right: 4px;
        top: 2px;
        width: 4px;
        height: 19px;
        background: @theme;
        border-radius: 2px 2px 2px 2px;
      }
    }
  }
}

// 加载
.load_box {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modal-content {
  .tips {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    color: @theme;
    img {
      margin-right: 7px;
    }
  }
  .input {
    height: 40px;
    margin-top: 20px;
  }
  .content {
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    margin: 20px 30px;
  }
  .btn-foot {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: 0 auto;
    margin-top: 30px;
    .cancel {
      text-align: center;
      line-height: 36px;
      border-radius: 23px;
      width: 113px;
      height: 36px;
      color: @theme;
      margin-right: 54px;
      border: 1px solid @theme;
      cursor: pointer;
    }
    .all-btn-small {
      width: 113px;
      height: 36px;
      line-height: 36px;
    }
  }
}
/deep/ .ant-modal-footer {
  display: none;
}
/deep/ .ant-modal {
  top: 260px;
}
/deep/.ant-menu-item {
  width: 300px;
  height: 60px;
  line-height: 60px;
}
@media screen and (max-width: 16000px) {
  .main-layout-wrap {
    display: flex;
    width: 1200px;
    margin: 20px auto 67px auto;
  }
  /deep/.side-l {
    width: 180px;
    height: 180px;
  }
  .main-wrap {
    .title-h4 {
      font-size: 22px !important;
    }
  }
}
.selhover {
  font-weight: 600;
  color: @theme !important;
}
/deep/.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  right: 300px;
  width: 6px;
  left: 0;
}
/deep/.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  font-weight: 600;
  color: @theme !important;
}
</style>
